'use es6';

const PROXY_URL_LANGUAGE_VARIANTS = {
  en: 'academy.hubspot.com',
  es: 'academy.hubspot.com/es',
  pt: 'academy.hubspot.com/pt',
  de: 'academy.hubspot.de',
  fr: 'academy.hubspot.fr',
  ja: 'academy.hubspot.jp'
};
const URL_LANG = /.*\/([a-z]{2})\/.*/;
const getPublicLang = (href = window.location.href) => {
  if (href.includes('academy.hubspot.de')) {
    return 'de';
  }
  if (href.includes('academy.hubspot.com/es')) {
    return 'es';
  }
  if (href.includes('academy.hubspot.fr')) {
    return 'fr';
  }
  if (href.includes('academy.hubspot.com/pt')) {
    return 'pt';
  }
  if (href.includes('academy.hubspot.jp')) {
    return 'ja';
  }
  const [__, locale] = href.match(URL_LANG) || [null, 'en'];
  return locale;
};
const PROXY_DOMAINS = Object.values(PROXY_URL_LANGUAGE_VARIANTS);
const makeEarlyRequest = endpoint => window.quickFetch.makeEarlyRequest(endpoint, {
  url: window.quickFetch.getApiUrl(endpoint, true),
  dataType: 'json',
  contentType: 'application/json',
  type: 'GET',
  noPortalId: true
});
const pathname = window.location.pathname;
if (pathname.includes('academy/public') || PROXY_DOMAINS.includes(window.location.hostname)) {
  // In public landing page app context
  makeEarlyRequest('/cookie-echo/v1/user');
  const isOnCatalogPage = pathname.match(/^(\/academy\/public)?\/courses(\/$|$)/) || pathname.match(/^(\/academy\/public)?\/courses\/(marketing|sales|service|software|page)(\/|$)/);
  if (!isOnCatalogPage) {
    const [___, pageType, pagePath] = pathname.match(/(courses|lessons)\/([A-Za-z0-9-_]+)/) || [];
    if (pageType === 'courses') {
      makeEarlyRequest(`/academy-catalog-public/v1/tracks/${getPublicLang()}/${pagePath}`);
    } else if (pageType === 'lessons') {
      makeEarlyRequest(`/academy-catalog-public/v1/lessons/${getPublicLang()}/${pagePath}`);
    }
  }
}